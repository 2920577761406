import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// components
import BodyHeader from "../../components/layout/BodyHeader";
import Button, { ButtonIcons, ButtonProps, ButtonTypes } from "../../components/common/Button";
import Layout from "../../components/layout";
import RenderIf from "../../components/common/RenderIf";

// models
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { equipmentAction } from "../../redux/actions/equipmentActions";

// helpers
import { EQUIPMENTS_DASHBOARD_PAGE_TITLE, layoutTitles } from "../../utils/constant";
import { EquipmentDashboardHelp } from "../../utils/helpContent";

const EquipmentIndex = () => {
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // permissions
  const hasReadPermission = user?.permissions?.updated?.equipment?.hasRead ?? false;
  const hasWritePermission = user?.permissions?.updated?.equipment?.hasReadAndWrite ?? false;

  // states
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  // Header Buttons
  const headerButtons: ButtonProps[] = [
    {
      isIconButton: true,
      navigateTo: "/",
      icon: ButtonIcons.HOME,
      title: "Home",
      type: ButtonTypes.SECONDARY,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => setOpenHelp(true)
    }
  ];

  // reset equipment action
  const resetEquipmentAction = () => {
    dispatch(equipmentAction(null));
  }

  // reset equipment action on component mount
  useEffect(() => {
    resetEquipmentAction();

    // Used in find page: fallback check
    if (localStorage?.getItem('page')) {
      localStorage?.removeItem('page');
    }
  }, []);

  return (
    <Layout title={layoutTitles.equipmentDashboard}>
      {/* Header */}
      <BodyHeader title={EQUIPMENTS_DASHBOARD_PAGE_TITLE} buttons={headerButtons} />

      {/* Body */}
      <div className="theme-card admin-h">
        <div className="body">
          <div className="md-container-2">
            <div className={`row btn-list ${hasWritePermission ? "justify-between" : "justify-center"}`}>
              <RenderIf condition={hasWritePermission}>
                <div className="col-md-5">
                  <Button
                    navigateTo="/equipments/new"
                    title="Add New Equipment"
                  />
                </div>
              </RenderIf>

              <RenderIf condition={hasReadPermission}>
                <div className="col-md-5">
                  <Button
                    navigateTo="/equipments/find"
                    title="Find Equipment"
                    onClick={resetEquipmentAction}
                  />
                </div>
              </RenderIf>
            </div>

            <RenderIf condition={hasReadPermission}>
              <div className="row btn-list justify-between">
                <div className="col-md-5">
                  <Button
                    navigateTo="/equipments/screening"
                    title="Equipment Screening"
                  />
                </div>
                
                <div className="col-md-5">
                  <Button
                    navigateTo="/equipments/models"
                    title="Equipment Models"
                  />
                </div>
              </div>
            </RenderIf>
          </div>
        </div>
      </div>

      {/* Modals */}
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={EquipmentDashboardHelp.title}
        content={EquipmentDashboardHelp.content} />
    </Layout>
  );
};

export default EquipmentIndex;
