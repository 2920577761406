// general
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormProvider, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";

// components
import Layout from "../../components/layout";
import FindGeneral from "../../components/equipment/FindGeneral";
import EquipmentHeaderRow from "../../components/equipment/HeaderRow";
import AuditTrail from "../../components/common/AuditTrail";

// tabs
import {
  Vessel,
  Reactor,
  Filtration,
  Mixer,
  Evaporator,
  Distillation,
} from "../../components/equipment/tabs";

// modals
import ConfirmCloneModal from "../../components/modals/ConfirmCloneModal";
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// services
import EquipmentService from "../../services/equipmentService";
import LogService from "../../services/logService";
import FileService from "../../services/fileService";

// redux
import { RootState } from "../../redux/store";

// interface
import { filesByCategoryProps } from "../../types/equipmentTypes";

// redux-actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { equipmentAction } from "../../redux/actions/equipmentActions";

// helpers
import { initialEquipmentData } from "../../utils/equipmentHelper";
import { FindEquipmentHelp } from "../../utils/equipmentHelpContent";
import { ERROR_MESSAGE } from "../../utils/constant";
import { apiRoutes } from "../../utils/apiRoutes";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import MaterialService from "../../services/materialService";
import {downloadFileFromURL} from "../../utils/downloadFile";
import CustomLoader from "../../components/common/CustomLoader";
import { setTitleNumberInput } from "../../utils/common";

const EquipmentFind = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory();

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
  }, );
  // user context
  const { user } = useUser();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // redux variables
  const equipment = useSelector((state: RootState) => state.equipment);

  // form methods
  const methods = useForm({
    defaultValues: initialEquipmentData(equipment.item),
  });

  // state variables
  const [loading, setLoading] = useState<boolean>(false);
  const [headerData, setHeaderData] = useState<any>();
  const [equipmentId, setEquipmentId] = useState<string | null>(null);
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [logs, setLogs] = useState<any[]>([]);
  const [deletableId, setDeletableId] = useState<any>(null);
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [equipmentDeleted, setEquipmentDeleted] = useState<number>(0);
  const [downloadingFile, setDownloadingFile] = useState(false);

  const [filesByCategory, setFilesByCategory] = useState<filesByCategoryProps>({
    vessel: {
      drawing: [],
      manufacturer_manual: [],
      sop: [],
      service_log: [],
    },
    reactor: {
      drawing: [],
      manufacturer_manual: [],
      sop: [],
      service_log: [],
    },
    distillation: {
      drawing: [],
      manufacturer_manual: [],
      sop: [],
      service_log: [],
    },
    filtration: {
      drawing: [],
      manufacturer_manual: [],
      sop: [],
      service_log: [],
    },
    evaporator: {
      drawing: [],
      manufacturer_manual: [],
      sop: [],
      service_log: [],
    },
    mixer: {
      drawing: [],
      manufacturer_manual: [],
      sop: [],
      service_log: [],
    },
  });

  // to find equipment
  useEffect(() => {
    if (user && params?.id) {
      setEquipmentId(params.id);
      viewEquipment();
    } else if (
      user &&
      headerData?.equipment_type &&
      headerData.equipment_owner &&
      headerData?.tag_number
    ) {
      findEquipment(headerData);
    } else {
      resetCategories();
      setLogs([]);
    }
  }, [
    headerData?.equipment_type,
    headerData?.equipment_owner,
    headerData?.tag_number,
    user,
  ]);

  // fill input fields with data from api response
  useEffect(() => {
    methods.reset(equipment?.item ?? {});
  }, [equipment.item]);

  // method to get equipment by Type, Owner and Tag Number.
  const findEquipment = async (data: any) => {
    const payload = {
      ...data,
      uid: `${auth?.claims?.uid}`,
    };
    setLoading(true); // enable loading
    const res = await EquipmentService.create(apiRoutes.FIND_EQUIPMENT, payload);
    if (res?.data?.code === 200) {
      setLoading(false); // disable loading

      let equipment = res?.data?.body;
      filesByCategory[equipment?.equipment_type as keyof filesByCategoryProps] =
        equipment[equipment?.equipment_type]?.attachments ?? [];

      dispatch(equipmentAction(equipment));
      setFilesByCategory({ ...filesByCategory });

      // Get selected equipment logs.
      res?.data?.body?.id && await getLogs(res?.data?.body?.id);
    } else {
      setLoading(false); // disable loading
    }
  };

  // method to get equipment by ID.
  const viewEquipment = async () => {
    setLoading(true); // enable loading
    const res = await EquipmentService.getById(params?.id);
    setLoading(false); // disable loading
    if (res?.data?.code === 200) {
      let equipment = res?.data?.body?.Item;
      dispatch(equipmentAction(equipment));
      filesByCategory[equipment?.equipment_type as keyof filesByCategoryProps] =
        equipment[equipment?.equipment_type]?.attachments ?? [];
      setFilesByCategory({ ...filesByCategory });

      // Get selected equipment logs.
      res?.data?.body?.Item?.id && await getLogs(res?.data?.body?.Item?.id);
    } else {
      setLoading(false); // disable loading
      dispatch(alertOpenAction(ERROR_MESSAGE, "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // method to get selected equipment logs.
  const getLogs = async (id: string) => {
    const payload = {
      entity_type: "equipment",
      entity_id: id,
      uid: `${auth?.claims?.uid}`,
    };
    const res = await LogService.get("/logs/get", payload);
    if (res?.data?.code === 200) {
      setLogs(res?.data?.body ?? []);
    }
  };

  const handleHeaderData = (data: any) => {
    methods.reset({});
    setLoading(data.loading);
    setHeaderData(data);
  };

  // method trigger on when user fill all required fields.
  const handleSelectedFields = (data: any) => {
    if (!(params && params.id)) {
      setLoading(data.loading);
      setHeaderData(data);
    }
  };

  // method to download selected file.
  const downloadFile = async (file: any) => {
    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data?.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction(ERROR_MESSAGE, "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // method to reset categories.
  const resetCategories = () => {
    setFilesByCategory({
      vessel: {
        drawing: [],
        manufacturer_manual: [],
        sop: [],
        service_log: [],
      },
      reactor: {
        drawing: [],
        manufacturer_manual: [],
        sop: [],
        service_log: [],
      },
      distillation: {
        drawing: [],
        manufacturer_manual: [],
        sop: [],
        service_log: [],
      },
      filtration: {
        drawing: [],
        manufacturer_manual: [],
        sop: [],
        service_log: [],
      },
      evaporator: {
        drawing: [],
        manufacturer_manual: [],
        sop: [],
        service_log: [],
      },
      mixer: {
        drawing: [],
        manufacturer_manual: [],
        sop: [],
        service_log: [],
      },
    });
  };

  const handleEditEquipment = (data: any) => {
    if (data && data?.id) {
      // dispatch(equipmentAction(null));
      localStorage.setItem("page", "find");
      history.push({
        pathname: `/equipments/${data?.id}/update`,
        state: { page: `find` },
      });
    } else {
      dispatch(alertOpenAction("Please select an equipment first!", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const cloneEquipment = (data: any) => {
    if (data?.id) {
      setOpenCloneModal(true);
    } else {
      dispatch(alertOpenAction("Please select an equipment first!", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const deleteHandle = async () => {
    setOpen(false); // enable loading
    setLoading(true); // enable loading
    const payload = {
      id: deletableId,
      uid: `${auth?.claims?.uid}`,
    };
    const res = await EquipmentService.deleteEquipment(payload);
    if (res?.data?.code === 200) {
      // methods.setValue("equipment_name", ""); // clear equipment_name field
      // methods.setValue("lot_number", ""); // clear equipment_name field
      //dispatch(equipmentAction(null)); // clear equipment object

      setEquipmentDeleted(Date.now());

    }

    setLoading(false);
  };

  const deleteEquipment = (data: any) => {
    if (data?.id) {
      setDeletableId(data.id);
      setConfirmMessage(`Are you sure you want to delete ${data.equipment_type} equipment with tag number “${data.tag_number}”?`);
      setOpen(true);

    } else {
      dispatch(alertOpenAction("Please select equipment first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }

  };

  const hasWritePermission = user?.permissions?.updated?.equipment?.hasReadAndWrite ?? false;

  const breadCrumbItems = [{ label: "Home", path: "/" }, { label: "Equipments", path: "/equipments" }, { label: params?.id ? "View Equipment" : "Find Equipment", path: "#" }];

  return (
    <Layout title={`Equipment ${params?.id ? "View" : "Find"}`} breadCrumbItems={breadCrumbItems}>
      {downloadingFile && <CustomLoader />}
      <ConfirmCloneModal
        open={openCloneModal}
        setOpen={setOpenCloneModal}
        type="Equipment"
        cloneId={equipment?.item?.id ?? null}
        uid={auth?.claims?.uid ?? ""}
        operation="cloneFromFind"
      />
      <DeleteConfirmModal
        open={open}
        confirmMessage={confirmMessage}
        setOpen={setOpen}
        deleteHandle={deleteHandle}

      />
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={FindEquipmentHelp.title}
        content={FindEquipmentHelp.content}
      />
      <FormProvider {...methods}>
        <div className="sec-info control-head">
          <div className="head">
            <h1 className="head-lg">
              {equipmentId ? "View" : "Find"} Equipment
            </h1>
          </div>
          <div className="right">
            {/* <Link to="#" className="icon-btn" title="Print">
              <i className="fa-solid fa-print" />
            </Link> */}
            {hasWritePermission && (
              <>
                <button
                  type="button"
                  className="icon-btn alter"
                  title="Delete"
                  onClick={() => deleteEquipment(equipment?.item)}
                >
                  <i className="fa-solid fa-trash" />
                </button>
                <Link
                  to="#"
                  className="icon-btn alter"
                  title="Edit"
                  onClick={() => {
                    handleEditEquipment(equipment?.item);
                  }}
                >
                  <i className="fa-solid fa-pen" />
                </Link>
                {!params?.id && (
                  <Link
                    to="#"
                    className="icon-btn alter"
                    title="Clone"
                    onClick={() => {
                      cloneEquipment(equipment?.item);
                    }}
                  >
                    <i className="fa-solid fa-clone" />
                  </Link>
                )}
              </>
            )}
            <Link to="/equipments" className="icon-btn alter" title="Close">
              <i className="fa-solid fa-xmark" />
            </Link>
            <Link
              to="#"
              className="icon-btn alter"
              title="Help"
              onClick={() => {
                setOpenHelp(true);
              }}
            >
              <i className="fa-solid fa-question" />
            </Link>
          </div>
        </div>
        {loading && equipmentId ? (
          <div className="theme-loader show fixed">
            <div className="loader-outer">
              <div className="loader"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <div className="theme-card">
                  <div className="body">
                    <EquipmentHeaderRow
                      headerData={handleHeaderData}
                      handleSelectedFields={handleSelectedFields}
                      equipmentDeleted={equipmentDeleted}
                      readOnly={equipmentId ? true : false}
                    />
                  </div>
                </div>
                {loading ? (
                  <div className="theme-loader show">
                    <div className="loader-outer">
                      <div className="loader"></div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="theme-card">
                      <FindGeneral equipment={equipment.item} />
                    </div>
                    <div className="theme-card">
                      {methods?.control?._formValues?.equipment_type ==
                      "vessel" ? (
                        <div className="body">
                          <Tabs>
                            <div className="admin-tabs mx-card mb-6">
                              <TabList className="inner mb-3">
                                <Tab>
                                  <div className="tab-link">Vessel</div>
                                </Tab>
                                <Tab>
                                  <div className="tab-link">Audit Trail</div>
                                </Tab>
                              </TabList>
                            </div>
                            <TabPanel>
                              <Vessel
                                readOnly={true}
                                attachments={filesByCategory?.vessel}
                                downloadFile={(file: any) => downloadFile(file)}
                              />
                            </TabPanel>
                            <TabPanel>
                              <div className="find-tab-scroll-trail">
                                <div className="flex w-full items-center justify-center">
                                  <button className="ml-auto theme-btn sm" type="button" onClick={async () => {
                                    let res;
                                    if (equipment?.item?.id) {
                                      setDownloadingFile(true);
                                        res = await EquipmentService.downloadAuditTrail({
                                          equipment_id: equipment.item.id
                                        })
                                      if (res && res?.data?.body) downloadFileFromURL(res.data.body, dispatch, `${equipment.item.id}.pdf`)
                                        .then(() => setDownloadingFile(false)).catch((err) => setDownloadingFile(false))
                                      else setDownloadingFile(false)
                                    }
                                  }}>Generate Audit Report
                                  </button>
                                </div>
                                  <AuditTrail items={logs} readable={true}/>
                                </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      ) : (
                          ""
                      )}
                      {methods?.control?._formValues?.equipment_type ==
                      "reactor" ? (
                          <div className="body">
                            <Tabs>
                              <div className="admin-tabs mx-card mb-6">
                                <TabList className="inner mb-3">
                                <Tab>
                                  <div className="tab-link">
                                    Reactor/Crystallizer
                                  </div>
                                </Tab>
                                <Tab>
                                  <div className="tab-link">Audit Trail</div>
                                </Tab>
                              </TabList>
                            </div>
                            <TabPanel>
                              <Reactor
                                readOnly={true}
                                attachments={filesByCategory?.reactor}
                                downloadFile={(file: any) => downloadFile(file)}
                              />
                            </TabPanel>
                              <TabPanel>
                                <div className="find-tab-scroll-trail">
                                  <div className="flex w-full items-center justify-center">
                                    <button className="ml-auto theme-btn sm" type="button" onClick={async () => {
                                      let res;
                                      if (equipment?.item?.id) {
                                        setDownloadingFile(true);
                                        res = await EquipmentService.downloadAuditTrail({
                                          equipment_id: equipment.item.id
                                        })
                                        if (res && res?.data?.body) downloadFileFromURL(res.data.body, dispatch, `${equipment.item.id}.pdf`)
                                          .then(() => setDownloadingFile(false)).catch((err) => setDownloadingFile(false))
                                        else setDownloadingFile(false)
                                      }
                                    }}>Generate Audit Report
                                    </button>
                                  </div>
                                  <AuditTrail items={logs} readable={true}/>
                                </div>
                              </TabPanel>
                            </Tabs>
                        </div>
                      ) : (
                        ""
                      )}
                      {methods?.control?._formValues?.equipment_type ==
                      "distillation" ? (
                        <div className="body">
                          <Tabs>
                            <div className="admin-tabs mx-card mb-6">
                              <TabList className="inner mb-3">
                                <Tab>
                                  <div className="tab-link">Distillation</div>
                                </Tab>
                                <Tab>
                                  <div className="tab-link">Audit Trail</div>
                                </Tab>
                              </TabList>
                            </div>
                            <TabPanel>
                              <Distillation
                                readOnly={true}
                                attachments={filesByCategory?.distillation}
                                downloadFile={(file: any) => downloadFile(file)}
                              />
                            </TabPanel>
                            <TabPanel>
                              <div className="find-tab-scroll-trail">
                                <div className="flex w-full items-center justify-center">
                                  <button className="ml-auto theme-btn sm" type="button" onClick={async () => {
                                    let res;
                                    if (equipment?.item?.id) {
                                      setDownloadingFile(true);
                                      res = await EquipmentService.downloadAuditTrail({
                                        equipment_id: equipment.item.id
                                      })
                                      if (res && res?.data?.body) downloadFileFromURL(res.data.body, dispatch, `${equipment.item.id}.pdf`)
                                        .then(() => setDownloadingFile(false)).catch((err) => setDownloadingFile(false))
                                      else setDownloadingFile(false)
                                    }
                                  }}>Generate Audit Report
                                  </button>
                                </div>
                                <AuditTrail items={logs} readable={true}/>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      ) : (
                        ""
                      )}
                      {methods?.control?._formValues?.equipment_type ==
                      "filtration" ? (
                        <div className="body">
                          <Tabs>
                            <div className="admin-tabs mx-card mb-6">
                              <TabList className="inner mb-3">
                                <Tab>
                                  <div className="tab-link">Filtration</div>
                                </Tab>
                                <Tab>
                                  <div className="tab-link">Audit Trail</div>
                                </Tab>
                              </TabList>
                            </div>
                            <TabPanel>
                              <Filtration
                                readOnly={true}
                                attachments={filesByCategory?.filtration}
                                downloadFile={(file: any) => downloadFile(file)}
                              />
                            </TabPanel>
                            <TabPanel>
                              <div className="find-tab-scroll-trail">
                                <div className="flex w-full items-center justify-center">
                                  <button className="ml-auto theme-btn sm" type="button" onClick={async () => {
                                    let res;
                                    if (equipment?.item?.id) {
                                      setDownloadingFile(true);
                                      res = await EquipmentService.downloadAuditTrail({
                                        equipment_id: equipment.item.id
                                      })
                                      if (res && res?.data?.body) downloadFileFromURL(res.data.body, dispatch, `${equipment.item.id}.pdf`)
                                        .then(() => setDownloadingFile(false)).catch((err) => setDownloadingFile(false))
                                      else setDownloadingFile(false)
                                    }
                                  }}>Generate Audit Report
                                  </button>
                                </div>
                                <AuditTrail items={logs} readable={true}/>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      ) : (
                        ""
                      )}
                      {methods?.control?._formValues?.equipment_type ==
                      "mixer" ? (
                        <div className="body">
                          <Tabs>
                            <div className="admin-tabs mx-card mb-6">
                              <TabList className="inner mb-3">
                                <Tab>
                                  <div className="tab-link">Mixer</div>
                                </Tab>
                                <Tab>
                                  <div className="tab-link">Audit Trail</div>
                                </Tab>
                              </TabList>
                            </div>
                            <TabPanel>
                              <Mixer
                                readOnly={true}
                                attachments={filesByCategory?.mixer}
                                downloadFile={(file: any) => downloadFile(file)}
                              />
                            </TabPanel>
                            <TabPanel>
                              <div className="find-tab-scroll-trail">
                                <div className="flex w-full items-center justify-center">
                                  <button className="ml-auto theme-btn sm" type="button" onClick={async () => {
                                    let res;
                                    if (equipment?.item?.id) {
                                      setDownloadingFile(true);
                                      res = await EquipmentService.downloadAuditTrail({
                                        equipment_id: equipment.item.id
                                      })
                                      if (res && res?.data?.body) downloadFileFromURL(res.data.body, dispatch, `${equipment.item.id}.pdf`)
                                        .then(() => setDownloadingFile(false)).catch((err) => setDownloadingFile(false))
                                      else setDownloadingFile(false)
                                    }
                                  }}>Generate Audit Report
                                  </button>
                                </div>
                                <AuditTrail items={logs} readable={true}/>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      ) : (
                        ""
                      )}
                      {methods?.control?._formValues?.equipment_type ==
                      "evaporator" ? (
                        <div className="body">
                          <Tabs>
                            <div className="admin-tabs mx-card mb-6">
                              <TabList className="inner mb-3">
                                <Tab>
                                  <div className="tab-link">Evaporator</div>
                                </Tab>
                                <Tab>
                                  <div className="tab-link">Audit Trail</div>
                                </Tab>
                              </TabList>
                            </div>
                            <TabPanel>
                              <Evaporator
                                readOnly={true}
                                attachments={filesByCategory?.evaporator}
                                downloadFile={(file: any) => downloadFile(file)}
                              />
                            </TabPanel>
                            <TabPanel>
                              <div className="find-tab-scroll-trail">
                                <div className="flex w-full items-center justify-center">
                                  <button className="ml-auto theme-btn sm" type="button" onClick={async () => {
                                    let res;
                                    if (equipment?.item?.id) {
                                      setDownloadingFile(true);
                                      res = await EquipmentService.downloadAuditTrail({
                                        equipment_id: equipment.item.id
                                      })
                                      if (res && res?.data?.body) downloadFileFromURL(res.data.body, dispatch, `${equipment.item.id}.pdf`)
                                        .then(() => setDownloadingFile(false)).catch((err) => setDownloadingFile(false))
                                      else setDownloadingFile(false)
                                    }
                                  }}>Generate Audit Report
                                  </button>
                                </div>
                                <AuditTrail items={logs} readable={true}/>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </FormProvider>
    </Layout>
  );
};

export default EquipmentFind;
