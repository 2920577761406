import React, { useState } from "react";
import  MaterialVisualizationTable, {
  Field,
  Material,
} from "../MaterialVisualizationTable";
import SecondaryLayout from "../../../components/layout/SecondayLayout";
import Button, { ButtonTypes } from "../../../components/common/Button";

const MaterialVisualize = () => {
  const materials: Material[] = [
    {
      name: "Material 1",
      vendor: "Vendor X",
      lot: "lot:001",
      boilingPoint: 10,
      price: 5,
      xrdImage1: "/path/to/image1.png",
      image2: "/path/to/image2.png",
      smiles: "CO2",
    },
    {
      name: "Material 2",
      vendor: "Vendor Y",
      lot: "lot:002",
      boilingPoint: 20,
      price: 1,
      xrdImage1: "/path/to/image3.png",
      image2: "/path/to/image4.png",
      smiles: "CCOCO2",
    },
  ];

  const fields: Field[] = [
    {
      label: "Boiling Point",
      key: "boilingPoint",
      type: "numeric",
      isNumeric: true,
      selected: true,
    },
    {
      label: "Price",
      key: "price",
      type: "numeric",
      isNumeric: true,
      selected: true,
    },
    {
      label: "XRD Img 1",
      key: "xrdImage1",
      type: "image",
      isNumeric: false,
      selected: true,
    },
    {
      label: "Img 2",
      key: "image2",
      type: "image",
      isNumeric: false,
      selected: true,
    },
    {
      label: "SMILES",
      key: "smiles",
      type: "text",
      isNumeric: false,
      selected: true,
    },
  ];

  const [selectedFields, setSelectedFields] = useState<Field[]>(fields);

  const handleFieldChange = (key: any) => {
    setSelectedFields((selectedFields) =>
      selectedFields.map((field) =>
        field.key === key ? { ...field, selected: !field.selected } : field
      )
    );
  };

  const resetFields = () => {
    setSelectedFields(fields);
  };

  return (
    <SecondaryLayout title="Material Visualize">
      <div className="flex flex-col md:flex-row h-screen p-4">
        <div className="md:w-1/4 bg-gray-100 p-4 rounded-md shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Filters</h2>
          <form>
            {fields.map((field) => (
              <div key={field.key} className="mb-2">
                <label className="custom-checkbox">
                  <input
                    className="ip"
                    type="checkbox"
                    checked={
                      selectedFields.find((f) => f.key === field.key)?.selected
                    }
                    onChange={() => handleFieldChange(field.key)}
                    title={field.label}
                  />
                  <span className="check"></span>
                  <span className="txt ">{field.label}</span>
                </label>
              </div>
            ))}
            <div className="mb-2 flex flex-wrap justify-evenly">
              <Button
                title="Reset"
                navigateTo="#"
                onClick={() => resetFields()}
                className="theme-btn sm alter"
                type={ButtonTypes.PRIMARY}
              />
            </div>
          </form>
        </div>
        <div className="md:w-3/4 bg-white p-4 rounded-md shadow-lg mt-4 md:mt-0 md:ml-4">
          <MaterialVisualizationTable
            materials={materials}
            fields={selectedFields}
          />
        </div>
      </div>
    </SecondaryLayout>
  );
};

export default MaterialVisualize;
