import { ButtonHTMLAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";

export enum ButtonIcons {
  HOME = "fa-solid fa-home",
  HELP = "fa-solid fa-question",
  VISUALIZE = "fa-solid fa-magnifying-glass-chart",
  EXPORT = "fa-solid fa-file-export",
  FILTER = "fa-solid fa-filter",
  CROSS = "fa-solid fa-xmark",
  SAVE = "fa-regular fa-floppy-disk",
  ARROW_LEFT = 'fa-solid fa-arrow-left',
  IMAGE = "fa-solid fa-image",
  EYE = "fa-regular fa-eye",
  CHART = "fa-solid fa-chart-simple"
}

export enum ButtonTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export interface ButtonProps {
  title?: string;
  onClick?: () => void;
  navigateTo?: string;
  icon?: ButtonIcons;
  isIconButton?: boolean;
  type?: ButtonTypes
  hideForDesktop?: boolean;
  disabled?: boolean;
  dataCy?: string;
  className?: string;
}

type ButtonElementProps = {
  isLink: boolean;
  to?: string;
  children: React.ReactNode;
  dataCy?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>
  & LinkProps;

const ButtonElement = ({ isLink, children,dataCy, ...rest }: ButtonElementProps) => {
  if (isLink) {
    return <Link {...rest} >{children}</Link>;
  }

  return <button data-cy={dataCy} {...rest} >{children}</button>;
}

const Button = (props: ButtonProps) => {
  const { title, onClick, navigateTo = "", icon, disabled= false, isIconButton = false, type = ButtonTypes.PRIMARY, hideForDesktop = false , dataCy, className} = props;

  const handleButtonClick = () => {
    if (typeof onClick === "function" && !disabled) {
      onClick();
    }
  }

  // Return Icon Button if isIconButton is true
  if (isIconButton) {
    return (
      <ButtonElement
        isLink={navigateTo !== ""}
        to={navigateTo}
        title={title}
        disabled={disabled}
        className={`icon-btn ${type === ButtonTypes.SECONDARY ? "alter" : ""} ${hideForDesktop ? "filter-responsive" : ""} ${disabled ? "disabled" : ""}`}
        onClick={handleButtonClick}
        dataCy={dataCy}
      >
        <i className={icon} />
      </ButtonElement>
    )
  }

  // Return Primary Button
  return (
    <Link
      to={navigateTo}
      title={title}
      className={`${className ? className : "theme-btn w-full brick fs-28"}`}
      onClick={handleButtonClick}
    >
      {title}
    </Link>
  )
}

export default Button;
