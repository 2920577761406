import { Modal, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormProvider, useForm } from 'react-hook-form';
import ExportFields from '../material/ExportFields';
import { gasInitialFields, liquidInitialFields, materialInitialFields, solidInitialFields } from '../../utils/materialHelper';
import { useDispatch } from 'react-redux';

const initialData = {
    solid: solidInitialFields,
    material: materialInitialFields,
    liquid: liquidInitialFields,
    gas: gasInitialFields
}

const VisualizeMaterialModal = (props: any) => {
    
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch()
    const methods = useForm({ defaultValues: initialData });

    useEffect(() => {
        methods.watch();
    })

    const checkSelected = () => {
        let materialArr: any = [];
        let gasArr: any = [];
        let solidArr: any = {};
        let liquidArr: any = [];

        solidArr = methods?.control?._formValues?.solid ?
            Object.keys(methods?.control?._formValues?.solid) && Object.keys(methods?.control?._formValues?.solid).map(
                (key: string) => {
                    let o: any = {};
                    o[key] = Object.keys(methods?.control?._formValues?.solid[key]).filter(
                        (item: string) => {
                            if (methods?.control?._formValues?.solid[key][item] == true) {
                                return item;
                            }
                        })
                    return o;
                }
            ) : ''
        gasArr = methods?.control?._formValues?.gas ?
            Object.keys(methods?.control?._formValues?.gas) && Object.keys(methods?.control?._formValues?.gas).filter(
                (key: string) => {
                    if (methods?.control?._formValues?.gas[key] == true) {
                        return key
                    }
                }
            ) : ''
        materialArr = methods?.control?._formValues?.material ?
            Object.keys(methods?.control?._formValues?.material) && Object.keys(methods?.control?._formValues?.material).filter(
                (key: string) => {
                    if (methods?.control?._formValues?.material[key] == true) {
                        return key
                    }
                }
            ) : ''
        liquidArr = methods?.control?._formValues?.liquid ?
            Object.keys(methods?.control?._formValues?.gas) && Object.keys(methods?.control?._formValues?.liquid).filter(
                (key: string) => {
                    if (methods?.control?._formValues?.liquid[key] == true) {
                        return key
                    }
                }
            ) : ''

        const data = {
            fields: materialArr && materialArr?.filter((item: any) => item !== 'selectAll'),
            liquid: liquidArr && liquidArr?.filter((item: any) => item !== 'selectAll') ,
            gas: gasArr && gasArr?.filter((item: any) => item !== 'selectAll'),
            solid: solidArr && solidArr?.filter((item: any) => item !== 'selectAll'),
        }
        return data;
    }

    return (
        <>
            <Modal
                open={props.open}
                size='large'
                dimmer='blurring'
                onClose={() => { methods.reset({}), props.setOpen(false) }}
            >
                <FormProvider {...methods}>
                    <form>
                        <Modal.Header align="center" className='border-none pb-0'>
                            <Icon name="times circle outline" onClick={() => { methods.reset({}), props.setOpen(false) }} color="grey" className='close-icon '></Icon>
                        </Modal.Header>
                        <Modal.Content scrolling>
                            <Modal.Description>
                                <Tabs>
                                    <div className='sticky-tab-list'>
                                        <p className='head-sm pt-3 text-left pl-4 pb-0 mb-0 pb-1'>Please select the fields you want to Visualize !</p>
                                        <div className="admin-tabs mb-6">
                                            <TabList className="inner mb-3 flex-wrap">
                                                <Tab>
                                                    <div className={`tab-link`}>Material</div>
                                                </Tab>
                                                <Tab>
                                                    <div className={`tab-link`}>{props.phase}</div>
                                                </Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                    <TabPanel>
                                        <ExportFields type="material" />
                                    </TabPanel>
                                    <TabPanel>
                                        <ExportFields type={props?.phase?.toLowerCase()} />
                                    </TabPanel>
                                    
                                </Tabs>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions className='border-none text-center bg-none mb-6 pt-3'>
                            <Button title="Export" color='red' type='submit' disabled={loading ? true : false} onClick={() => {window.open("/materials/visualize", "_blank")}}>
                                {loading ?
                                    <>
                                        <i className="fa-solid fa-circle-notch fa-spin"></i> Visualize
                                        <div className="theme-loader">
                                            <div className="loader-outer">
                                                <div className="loader"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        Visualize
                                    </>
                                }
                            </Button>
                            <Button title="Cancel" onClick={() => { methods.reset({}), props.setOpen(false) }}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    </form>
                </FormProvider>
            </Modal>
        </>
    )
};
export default VisualizeMaterialModal;