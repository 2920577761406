import Sortable from "../../solubility/Sortable";

export enum TableHeadType {
  CHECKBOX = "checkbox",
  EXPAND = "expand",
  SORTABLE = "sortable",
  CUSTOM_CELL = "custom-cell",
  DEFAULT = "default"
}

export interface TableHeadProps {
  title?: string;
  fieldName?: string;
  type?: TableHeadType;
  alignCenter?: boolean;
  sortBy?: string;
  sortOrder?: string;
  manageSort?: (fieldName: string) => void;
  onClick?: (e: any) => void;
  checked?: boolean;
  style?: string;
}

const TableHead = (props: TableHeadProps) => {
  const { title, fieldName, onClick, checked, alignCenter = false, sortBy, sortOrder, manageSort, type = TableHeadType.DEFAULT, style = '' } = props;

  switch (type) {
    case TableHeadType.CHECKBOX:
      return (
        <th className={`align ${style}`}>
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
          />
        </th>
      )

    case TableHeadType.SORTABLE:
      return (
        <th className={style}>
          <Sortable
            title={title}
            fieldName={fieldName}
            alignCenter={alignCenter}
            sortBy={sortBy}
            sortOrder={sortOrder}
            manageSort={manageSort}
          />
        </th>
      )

    default:
      return (
        <th className={style}>
          {title}
        </th>
      )
  }
}

interface TableHeaderProps {
  heads: TableHeadProps[];
}

const TableHeader = (props: TableHeaderProps) => {
  return (
    <thead>
      <tr>
        {props.heads?.map((head, index) => (
          <TableHead key={`${head.title}-${index}`} {...head} />
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader;
