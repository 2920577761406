import React, { useState } from 'react';
import Button, { ButtonIcons, ButtonTypes } from '../../components/common/Button';
import { Modal } from 'semantic-ui-react';

export interface Material {
  name: string;
  vendor: string;
  lot: string;
  [key: string]: any; // Dynamic property keys for different material attributes
}

export interface Field {
  label: string;
  key: string;
  type: 'numeric' | 'image' | 'text';
  isNumeric?: boolean;
  selected?: boolean;
}

export interface MaterialVisualizationTableProps {
  materials: Material[];
  fields: Field[];
}

const MaterialVisualizationTable: React.FC<MaterialVisualizationTableProps> = ({ materials, fields }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState<string[]>([]);

  // Function to handle opening modal
  const openImageModal = (images: string[]) => {
    setCurrentImages(images);
    setIsOpen(true);
  };

  // Function to close the modal
  const closeImageModal = () => {
    setIsOpen(false);
    setCurrentImages([]);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border border-gray-200">
        <thead className='sticky top-0 z-10 bg-gray-100'>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 border border-gray-300"></th> {/* Empty for property names */}
            {materials.map((material, index) => (
              <th key={index} className="px-4 py-2 border border-gray-300 text-center">
                {material.name} <br /> {material.vendor} <br /> {material.lot}
              </th>
            ))}
            <th className="px-4 py-2 border border-gray-300 text-center">Graph</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, fieldIndex) => (
            field.selected && <tr key={fieldIndex}>
              <td className="px-4 py-2 border border-gray-300">{field.label}</td>
              {materials.map((material, materialIndex) => (
                <td key={materialIndex} className="px-4 py-2 border border-gray-300 text-center">
                  {field.type === 'image' ? (
                    // Check if the field contains multiple images and render them
                    <Button
                      title="View Image"
                      navigateTo="#"
                      className="theme-btn sm alter"
                      onClick={() => openImageModal(material[field.key])}
                      type={ButtonTypes.PRIMARY}
                    />

                  ) : (
                    material[field.key]
                  )}
                </td>
              ))}
              <td className="px-4 py-2 border border-gray-300 text-center">
                {field.isNumeric && (
                  <Button
                    title='Visualize'
                    isIconButton={true}
                    icon={ButtonIcons.CHART}
                    onClick={() => { }}
                    type={ButtonTypes.PRIMARY}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>

        {/* Sticky Last Row */}
        <tfoot className="sticky bottom-0 z-10 bg-gray-100">
          <tr>
            <td className="px-4 py-2 border border-gray-300">View Material</td>
            {materials.map((material, index) => (
              <td key={index} className="px-4 py-2 border border-gray-300 text-center">
                <button type="button" className="icon-btn sm plain" title="View" onClick={() => { }}>
                  <i className="fa-solid fa-eye" />
                </button>
              </td>
            ))}
            <td className="px-4 py-2 border border-gray-300 text-center"></td>
          </tr>
        </tfoot>
      </table>

      {/* Image Modal */}
      
        <Modal
          onClose={() => closeImageModal()}
          open={isOpen}
          closeIcon
        ><Modal.Header>Images</Modal.Header>
          <Modal.Content>
            {/* {currentImages.map((image, index) => (
        //         <img
        //           key={index}
        //           src={image}
        //           alt={`Material Image ${index}`}
        //           className="w-full h-auto"
        //         />
        //       ))} */}
          </Modal.Content>
        </Modal>

      
    </div>
  );
};

export default MaterialVisualizationTable;
