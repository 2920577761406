import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/logo_takeda.png';

// components
import Header from "./Header";
import Sidebar from "./Sidebar";

import { RootState } from "../../redux/store";
import { LayoutProps } from "../../types";


export const SecondaryLayout = ({ children, title }: LayoutProps) => {
  const alert = useSelector((state: RootState) => state.alert);

  useEffect(() => {
    document.title = `Takeda | ${title ? title : 'Home'}`;
    if (alert.open && alert?.message && alert.alertType == "error") {
      toast.dismiss(); toast.error(alert.message)
    }
    if (alert.open && alert?.message && alert?.alertType == "success") {
      toast.dismiss(); toast.success(alert.message)
    }
  }, [alert]);
  return (
    <main className="main-wrapper user-main m-normal">
      <header className="admin-header">
        <div className="side-head">
          <span className="img">
            <img
              src={logo}
              alt="Logo"
              className="img-fluid fav"
              height="860"
              width="2560"
            />
            <div className="pl-6">
              <h2>{title}</h2>
            </div>
          </span>
        </div>
      </header>
      <div className="inner-container">
        <div className="main-data">{children}</div>
      </div>
    </main>
  )
}

export default SecondaryLayout;