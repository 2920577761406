import { Controller, useForm } from "react-hook-form";
import { MultiFileUploadProps } from "../../types";
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";

const MultiFileUpload = ({
    title,
    name,
    hoverText,
    acceptFormat,
    readable,
    showDownload,
    hasPermission,
    hasAttachmentTypes,
    attachmentsTypes,
    setSelectedAttachmentType,
    handleFileChange,
    removeFile,
    attachments,
    downloadFile,
    dataCy,
    multiple=true
}: MultiFileUploadProps) => {
    const { control, watch } = useForm({
        // resolver: yupResolver(schema), defaultValues: initialData
    });

    watch(() => setSelectedAttachmentType?.(watch('selectedAttachmentType')));

    return (
        <>
            {!readable ? (
                <>
                    {hasAttachmentTypes && (
                        <div className="form-group">
                            <label className="ip-wrap" htmlFor="chemicalSafety">
                                <span className="form-label">Attachments</span>
                                <div className="input-wrap select-outer">
                                    <Controller
                                        name="selectedAttachmentType"
                                        control={control}
                                        render={({ field }) => (
                                            <select className="theme-ip" {...field}>
                                                <option value="">Select</option>
                                                {attachmentsTypes?.map((attachmentsType, index: number) => (
                                                    <option key={index} value={attachmentsType.value}>
                                                        {attachmentsType.label}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    />
                                </div>
                            </label>
                        </div>
                    )}
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="">
                            <span className="form-label" title={hoverText}>{title}</span>
                            {hasPermission || typeof hasPermission === 'undefined' ? (
                                <div className="custom-upload">
                                    {/* <Controller
                                        name="process_attachment"
                                        control={control}
                                        render={({ field }) => <input className="theme-ip" type="file" {...field} />}
                                    /> */}
                                    <input
                                        type="file"
                                        name={name}
                                        data-cy={dataCy}
                                        onChange={handleFileChange} style={{ display: 'none' }}
                                        onClick={(event: React.MouseEvent<HTMLInputElement>) => event.currentTarget.value = ""}
                                        accept={acceptFormat ? acceptFormat : ""}
                                        multiple={multiple}
                                    />
                                    <div className="theme-ip" onClick={() => (document.querySelector(`input[name="${name}"]`) as HTMLInputElement)?.click()}>
                                        <i className="fa-solid fa-paperclip" />
                                        {!attachments.length ? (<span  className="text">No File Choosen</span>):
                                          (<span className="theme-btn sm light-btn">
                                            Choose
                                        </span>)}
                                    </div>
                                </div>
                            ) : null}
                        </label>
                    </div>
                    {attachments?.length ? (
                        <div className="uploaded-files">
                            <label className="form-label">Uploaded Attachments</label>
                            {attachments.map((attachment: any, index: number) => {
                                return (
                                  <div key={index} className={`upfile ${attachment.error ? 'failed' : ''}`}>
                                    <span onClick={() => removeFile(index, name)}>
                                        <i className={`fa-solid ${attachment.error ? 'fa-warning' : 'fa-file'}`} />
                                    </span>
                                      <div className="text">
                                          <div className="type">{attachment?.category}</div>
                                          <div className="name ">
                                              <div className="attachment-name">{attachment?.file?.name || attachment?.display_name}</div>
                                              {attachment?.error && <label className="error text-xs">Maximum file size is {UPLOAD_FILE_MAXLENGTH}mb only.</label>}
                                          </div>
                                      </div>

                                      <span className="ml-auto full-flex">
                                        {hasPermission || typeof hasPermission === 'undefined' ? <i className="fa-solid fa-xmark close block" onClick={() => removeFile(index, name)} /> : null}
                                          {showDownload && attachment.isUpload && <i className="fa-solid fa-download close ml-3 block" onClick={() => downloadFile?.(attachment)} />}
                                    </span>
                                  </div>
                                )
                            })}
                            {/* <div className="upfile in-process">
                                <i className="fa-solid fa-file" />
                                <div className="text">
                                    <div className="type">Molecular Structure</div>
                                    <div className="name">customname1.png</div>
                                </div>
                                <i className="fa-solid fa-xmark close" />
                                <div className="progress">
                                    <div
                                        className="inner"
                                        style={{ width: '60%' }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className="upfile">
                                <span onClick={removeFile}>
                                    <i className="fa-solid fa-file" />
                                </span>
                                <div className="text">
                                    <div className="type">USP Monograph</div>
                                    <div className="name">jsabfkbdjfbjkkk.png</div>
                                </div>
                                <span onClick={removeFile}>
                                    <i className="fa-solid fa-xmark close" />
                                </span>
                            </div>
                            <div className="upfile">
                                <i className="fa-solid fa-file" />
                                <div className="text">
                                    <div className="type">Chemical Safety</div>
                                    <div className="name">popopmjbjb.png</div>
                                </div>
                                <span onClick={removeFile}>
                                    <i className="fa-solid fa-xmark close" />
                                </span>
                            </div> */}
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <div className="uploaded-files">
                        <label className="form-label mt-3">{title}</label>
                        {
                            attachments?.length ? attachments.map((attachment: any, index: number) => {
                                return (

                                    <div key={index} className="upfile">
                                        <span>
                                            <i className="fa-solid fa-file" />
                                        </span>
                                        <div className="text">
                                            <div className="type">{attachment?.category}</div>
                                            <div className="name">{attachment?.file?.name || attachment?.display_name}</div>

                                        </div>

                                        <span>
                                            <i className="fa-solid fa-download close" onClick={() => downloadFile?.(attachment)} />
                                        </span>
                                    </div>
                                )
                            }) :
                                <div className="upfile">
                                    {/* <span>
                                        <i className="fa-solid fa-file
                                        " />
                                    </span> */}
                                    {/*Add up class to make opacity 1 */}
                                    <input
                                        data-cy={dataCy}
                                        className="theme-ip disabled"
                                        disabled
                                        value="There is no file."
                                    />
                                </div>
                        }

                    </div>
                </>
            )}
        </>
    );
};

export default MultiFileUpload;